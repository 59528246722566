import React from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions"
import colors from "styles/colors"
import PropTypes from "prop-types"

const ProjectCardContainer = styled(Link)`
  display: grid;
  grid-template-columns: 4fr 7fr;
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
  margin-bottom: 4em;
  transition: all 150ms ease-in-out;
  text-decoration: none;
  color: currentColor;

  @media (max-width: 950px) {
    grid-template-columns: 4.5fr 7fr;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    margin-bottom: 2em;
  }

  &:hover {
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
    transition: all 150ms ease-in-out;

    .ProjectCardAction {
      color: #f87b8e;
      transition: all 150ms ease-in-out;

      span {
        transform: translateX(0px);
        opacity: 1;
        transition: transform 150ms ease-in-out;
      }
    }

    .ProjectCardContent::before {
      opacity: 0.02;
      transition: all 150ms ease-in-out;
    }

    .ProjectCardImageContainer::before {
      opacity: 0.2;
      transition: all 150ms ease-in-out;
    }
  }
`

const ProjectCardContent = styled("div")`
  background: white;
  padding: 4em 3em 2.25em 3em;
  position: relative;
  background-color: ${({ background }) => background};

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${colors.blue500};
    mix-blend-mode: multiply;
    opacity: 0;
    transition: all 150ms ease-in-out;
  }

  @media (max-width: 950px) {
    padding: 3.25em 2.5em 2em 2.5em;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-row: 2;
  }
`

const ProjectCardCategory = styled("h6")`
  font-weight: 600;
  color: ${colors.blue900};
`

const ProjectCardTitle = styled("h3")`
  margin-bottom: 0.5em;
  margin-top: 0.5em;
`

const ProjectCardBlurb = styled("div")`
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 5em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin-bottom: 2.5em;
  }
`

const ProjectCardAction = styled("div")`
  font-weight: 600;
  text-decoration: none;
  color: currentColor;
  transition: all 150ms ease-in-out;

  span {
    margin-left: 1em;
    transform: translateX(-8px);
    display: inline-block;
    transition: transform 400ms ease-in-out;
  }
`

const Dot = styled("div")`
  height: 10px;
  width: 10px;
  background-color: #f87b8e;
  border-radius: 50%;
`

const DotContainer = styled("div")`
  width: 50px;
  padding-top: 8px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
`

const ToolBarContainer = styled("div")`
  height: 25px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-color: #031b88;
`

const ProjectCardImageContainer = styled("div")`
  background: ${colors.grey200};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 2rem;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    max-height: 200px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${colors.blue500};
    mix-blend-mode: multiply;
    opacity: 0;
    transition: all 150ms ease-in-out;
  }

  img {
    width: 100%;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);

    @media (max-width: ${dimensions.maxwidthTablet}px) {
      max-width: 400px;
    }
  }
`

const ProjectCard = ({ category, title, description, thumbnail, uid }) => (
  <div>
    <ToolBarContainer>
      <DotContainer>
        <Dot />
        <Dot />
        <Dot />
      </DotContainer>
    </ToolBarContainer>
    <ProjectCardContainer to={`/work/${uid}`}>
      <ProjectCardContent className="ProjectCardContent">
        <ProjectCardCategory>{category[0].text}</ProjectCardCategory>
        <ProjectCardTitle>{title[0].text}</ProjectCardTitle>
        <ProjectCardBlurb>{RichText.render(description)}</ProjectCardBlurb>
        <ProjectCardAction className="ProjectCardAction">
          Details <span>&#8594;</span>
        </ProjectCardAction>
      </ProjectCardContent>
      <ProjectCardImageContainer className="ProjectCardImageContainer">
        <img src={thumbnail.url} alt={title[0].text} />
      </ProjectCardImageContainer>
    </ProjectCardContainer>
  </div>
)

export default ProjectCard

ProjectCard.propTypes = {
  category: PropTypes.array.isRequired,
  thumbnail: PropTypes.object.isRequired,
  title: PropTypes.array.isRequired,
  description: PropTypes.array.isRequired,
  uid: PropTypes.string.isRequired,
}
